import React from "react";
import ConnectingButton from "./ConnectingButton";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

const ConnectingButtons = () => {
  let navigate = useNavigate();
  //   let navigate = useNavigate();
  const joinRoom = () => {
    navigate("/join-room");
    // navigate("/join-room");
  };

  const hostRoom = () => {
    // navigate("/join-room?host=true");
    navigate("/join-room?host=true");
    // window.location.reload();
  };

  return (
    <div className="connecting_buttons_container">
      <ConnectingButton buttonText="Join a Meeting" onClickHandler={joinRoom} />
      <ConnectingButton
        createRoomButton
        buttonText="Host a Meeting"
        onClickHandler={hostRoom}
      />
    </div>
  );
};

export default ConnectingButtons;

export const getClass = (name) => {
  const firstChar = name.substring(0, 1).toLowerCase();
  switch (firstChar) {
    case ("a", "b", "c", "d", "e"):
      return "circle a-color";
    case ("f", "g", "h", "i", "j"):
      return "circle e-color";
    case ("k", "l", "m", "n", "o"):
      return "circle i-color";
    case ("p", "q", "r", "s", "t", "u"):
      return "circle o-color";
    case ("v", "w", "x", "y", "z"):
      return "circle u-color";
    default:
      return "circle d-color";
  }
};

export const getLocalTime = (date_time) => {
  var userLocale = navigator.language || navigator.userLanguage || "en-US";
  var options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true, // or false if you prefer 24-hour format
  };
  var formattedTime = date_time.toLocaleTimeString(userLocale, options);

  return formattedTime;
};

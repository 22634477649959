import React, { useState } from "react";
import ParticipantsSection from "../ParticipantsSection/ParticipantsSection";
import {
  setShowMenu,
  setShowParticipant,
  setShowPopUsers,
  setShowPopUsersAction,
} from "../../store/actions";
import { connect } from "react-redux";
import PopupUsers from "../../users/PopupUsers";

const Popup = (props) => {
  const {
    setShowMenuAction,
    setShowParticipantAction,
    setShowPopUsersAction,
    showMenu,
    showParticipant,
    showPopUsers,
  } = props;
  //if (!showMenu) return null; // Don't render if popup is not open
  const menuOptionClickHandler = (option) => {
    setShowMenuAction(!showMenu);
    if (option == "show-pariticipants") {
      setShowPopUsersAction(false);
      setShowParticipantAction(true);
    } else {
      setShowPopUsersAction(true);
      setShowParticipantAction(false);
    }
  };

  return (
    <>
      {showMenu && (
        <div className="popup open">
          {/* Your popup content here */}
          <ul>
            <li
              onClick={() => {
                menuOptionClickHandler("show-pariticipants");
              }}
            >
              Participants
            </li>
            <li
              onClick={() => {
                menuOptionClickHandler("invite-attendees");
              }}
            >
              Invite Attendee
            </li>
          </ul>
        </div>
      )}{" "}
      {showParticipant && <ParticipantsSection />}
      {showPopUsers && <PopupUsers />}
    </>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setShowMenuAction: (showMenu) => dispatch(setShowMenu(showMenu)),
    setShowParticipantAction: (showParticipant) =>
      dispatch(setShowParticipant(showParticipant)),
    setShowPopUsersAction: (showPopUsers) =>
      dispatch(setShowPopUsers(showPopUsers)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(Popup);

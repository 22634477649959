import React, { useState } from "react";
import threedots from "../../resources/images/users.png";
import Popup from "./Popup";
import {
  setShowMenu,
  setShowParticipant,
  setShowChat,
  setShowPopUsers,
} from "../../store/actions";
import { connect } from "react-redux";
import PopupUsers from "../../users/PopupUsers";
import { Tooltip } from "react-tooltip";
import store from "../../store/store";

const MenuButton = (props) => {
  const { setShowChatAction, setShowPopUsersAction, showPopUsers } = props;

  const handleMenuButtonPressed = () => {
    setShowChatAction(false);
    setShowPopUsersAction(!showPopUsers);
  };
  return (
    <>
      <div className="video_button_container">
        <div className="icon-container">
          <div className="badge">{store.getState().participants.length}</div>
          <Tooltip id="my-tooltip" />
          <img
            className="video_button_dots_image"
            src={threedots}
            onClick={handleMenuButtonPressed}
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Participants"
          />
        </div>
      </div>
      {showPopUsers && <PopupUsers />}
    </>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setShowPopUsersAction: (showPopUsers) =>
      dispatch(setShowPopUsers(showPopUsers)),
    setShowChatAction: (showChat) => dispatch(setShowChat(showChat)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(MenuButton);

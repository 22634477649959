import React, { useState } from "react";
import chat from "../../resources/images/chat.png";
import { setShowChat, setShowPopUsers } from "../../store/actions";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import PopupMessages from "../ChatSection/PopupMessages";
import store from "../../store/store";

const ChatButton = (props) => {
  const { setShowChatAction, showChat, setShowPopUsersAction } = props;

  const handleMenuButtonPressed = () => {
    setShowPopUsersAction(false);
    setShowChatAction(!showChat);
  };
  return (
    <>
      <div className="video_button_container">
        <div className="icon-container">
          {store.getState().messages.length > 0 && (
            <div className="blue-circle"></div>
          )}
          <Tooltip id="my-tooltip" />
          <img
            className="video_button_dots_image"
            src={chat}
            onClick={handleMenuButtonPressed}
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Chat"
          />
        </div>
      </div>

      {showChat && <PopupMessages />}
    </>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setShowChatAction: (showChat) => dispatch(setShowChat(showChat)),
    setShowPopUsersAction: (showPopUsers) =>
      dispatch(setShowPopUsers(showPopUsers)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(ChatButton);

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ParticipantsLabel from "../roompage/ParticipantsSection/ParticipantsLabel";
import Participants from "../roompage/ParticipantsSection/Participants";
import { store } from "../store/store";
import { setShowPopUsers, setIsRoomHost, setToJid } from "../store/actions";
import { Strophe, $msg, $pres, $iq } from "strophe.js";
import config from "../config";
import { inviteToRoom } from "../utils/wss";
import { Tooltip } from "react-tooltip";

const PopupUsers = (props) => {
  const {
    connection,
    team,
    setShowPopUsersAction,
    fromJid,
    participants,
    clientUsers,
    hideClient,
  } = props;
  const [prevClientId, setPrevClientId] = useState("");
  const [prevId, setPrevId] = useState("");
  const [newClientUsers, setNewClientUsers] = useState(clientUsers);
  const [newConnection, setNewConnection] = useState(connection);
  const [newTeam, setNewTeam] = useState(team);
  const [myPresence, setMyPresence] = useState({
    show: "chat", // Default presence status, can be 'chat', 'away', etc.
    status: "Available", // Default status message
  });
  const [receivedPresenceUpdate, setReceivedPresenceUpdate] = useState(false);

  //const fromJid = store.getState().fromJid;
  const toJid = store.getState().toJid;
  // console.log(store.getState().participants);
  const myJid = store.getState().fromJid;
  const authToken = store.getState().cofferToken;
  const loginType = store.getState().loginType;
  //   const connection = [];
  //   const newTeam = [];
  const decodedToken = {};

  const toggleExpand = (itemId) => {
    //Expand the current object
    setNewTeam((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, isExpanded: !item.isExpanded } : item
      )
    );
    //collapse the previous expanded object
    if (prevId !== "" && prevId !== itemId) {
      setNewTeam((prevItems) =>
        prevItems.map((item) =>
          item.id === prevId ? { ...item, isExpanded: false } : item
        )
      );
    }
    setPrevId(itemId);
  };

  const toggleClientExpand = async (itemId) => {
    //following code is less process intensive, but for some issue so need to check
    if (prevClientId !== itemId) {
      const cl = newConnection.find((client) => client.id === itemId);
      // console.log(cl);
      if (cl) setNewClientUsers(cl.users);
      else setNewClientUsers([]);
    }

    setNewConnection((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, isExpanded: !item.isExpanded } : item
      )
    );

    // setConnectionAction((prevItems) =>
    //   prevItems.map((item) =>
    //     item.id === itemId ? { ...item, isExpanded: !item.isExpanded } : item
    //   )
    // );

    //collapse the previous expanded object
    if (prevClientId !== "" && prevClientId !== itemId) {
      setNewConnection((prevItems) =>
        prevItems.map((item) =>
          item.id === prevClientId ? { ...item, isExpanded: false } : item
        )
      );
    }
    setPrevClientId(itemId);
  };

  const makeCall = (client) => {
    // setIsRoomHostAction(false);
    // setToJidAction(`${client.toJid}@${config.xmppServer}`);
    // navigate("/room");
    //console.log(client, fromJid, client.toJid, store.getState().roomId);

    inviteToRoom(
      client.fullName,
      false,
      fromJid,
      `${client.toJid}@${config.xmppServer}`,
      store.getState().roomId
    );
    setShowPopUsersAction(false);
  };

  const closeDiv = () => {
    setShowPopUsersAction(false);
  };

  const changeOnlineStatus = (jid, newStatus) => {
    // console.log(` Received presence from <<${jid}>>`);
    let nClients = newConnection;
    let nTeam = newTeam;
    let cUsers = newClientUsers;
    nClients.forEach((client) => {
      //Unsure when to send client offline, in this case. Otherwise it is toggling
      // client.online = client.guid === jid ? true : false;
      client.online = client.guid === jid ? true : client.online;
    });
    // setNewClients((prevStates) =>
    //   prevStates.map((client) =>
    //     client.guid === jid ? { ...client, online: newStatus } : client
    //   )
    // );
    nTeam.forEach((group) => {
      group.users.forEach((user) => {
        user.online = user.guid === jid ? true : user.online;
      });
    });

    cUsers.forEach((user) => {
      //Unsure when to send client offline, in this case. Otherwise it is toggling
      // client.online = client.guid === jid ? true : false;
      const isOnline = user.online ? user.online : false;
      user.online = user.guid === jid ? true : isOnline;
    });
    // console.log(cUsers);
    // setClients(nClients);
    setNewConnection(nClients);
    setNewTeam(nTeam);
    setNewClientUsers(cUsers);
  };

  useEffect(() => {
    // Initialize XMPP connection
    // console.log(loginState);
    if (authToken !== "" && myJid !== "") {
      const xmppConnection = new Strophe.Connection(
        `wss://${config.xmppServer}:5443/ws`,
        { protocol: "wss" }
      );
      // console.log(xmppConnection);
      // const myJid = authData.uid + "_" + authData.user_id;

      console.log(`connecting as ${myJid}`);
      xmppConnection.connect(myJid, authToken, onConnect);
      //setConnection(xmppConnection);

      function onConnect(status) {
        // console.log(`current status = ${status}`);
        // console.log(`open = ${Strophe.Status.CONNECTED}`);
        if (status === Strophe.Status.CONNECTED) {
          console.log("connected...");
          setupMessageListener();
          //for loop here to each of the clients
          //#SATHIKUM need to call api /notify for each relationship and then subscribe
          //https://apidev2.digicoffer.com/professional/relationship/65b9e6c9a1db720418e50c8a/users/notify
          newConnection.forEach((cl) => {
            subscribeToPresence(`${cl.guid}@${config.xmppServer}`);
          });
          //Team Member code here
          newTeam.forEach((tm) => {
            tm.users.forEach((user) => {
              subscribeToPresence(`${user.guid}@${config.xmppServer}`);
            });
          });

          newClientUsers.forEach((cl) => {
            subscribeToPresence(`${cl.guid}@${config.xmppServer}`);
          });
        } else if (status === Strophe.Status.DISCONNECTED) {
          console.log("Disconnected from XMPP server");
        }
      }

      const setupMessageListener = () => {
        const presenceStanza = $pres();
        presenceStanza.c("show").t("chat"); // 'chat' represents 'available' status
        presenceStanza.c("status").t("Available for a chat");

        xmppConnection.deleteHandler(onPresence);
        xmppConnection.addHandler(onPresence, null, "presence");
        //xmppConnection.send($pres()); // Send presence to indicate online status
        xmppConnection.send(presenceStanza);
      };

      const sendPresence = () => {
        const presenceStanza = $pres();
        presenceStanza.c("show").t("chat"); // 'chat' represents 'available' status
        presenceStanza.c("status").t("Available for a chat");
        xmppConnection.deleteHandler(onPresence);
        xmppConnection.addHandler(onPresence, null, "presence");
        xmppConnection.send(presenceStanza);
        // console.log("presence sent --->");
      };

      const onPresence = (presence) => {
        // console.log(`Received prssence => ${presence}`);
        // console.log(presence);
      };

      const subscribeToPresence = (jid) => {
        xmppConnection.deleteHandler(onPresenceUpdate);

        xmppConnection.addHandler(
          onPresenceUpdate,
          null,
          "presence",
          null,
          null,
          jid,
          { matchBare: true }
        );
        xmppConnection.send($pres({ to: jid }));
      };

      const onPresenceUpdate = (presenceStanza) => {
        // Handle the incoming presence update
        // console.log("Got Presense update...");
        const fromJID = Strophe.getBareJidFromJid(
          presenceStanza.getAttribute("from")
        );
        // console.log(fromJID.replace(/@.*/gm, ""));
        changeOnlineStatus(fromJID.replace(/@.*/gm, ""), true);
        // console.log(newClients);
        const show = presenceStanza.querySelector("show");
        const status = presenceStanza.querySelector("status");
        // Update your own presence based on the received presence
        const updatedPresence = {
          show: show ? show.textContent : "chat", // Default to 'chat' if not available
          status: status ? status.textContent : "Available", // Default to 'Available' if not available
        };
        setMyPresence(updatedPresence);
        setReceivedPresenceUpdate(true);

        //sendPresence();
        // You can now use the presence information in your React component state or perform any other actions.
      };

      const myPresenceInterval = setInterval(() => {
        sendPresence();
      }, 3000); // 3000 milliseconds = 3 seconds

      const presenceInterval = setInterval(() => {
        //#SATHIKUM needs a relook because of the //notify API
        newConnection.forEach((cl) => {
          subscribeToPresence(`${cl.guid}@${config.xmppServer}`);
        });

        newTeam.forEach((tm) => {
          tm.users.forEach((user) => {
            subscribeToPresence(`${user.guid}@${config.xmppServer}`);
          });
        });

        // console.log(newClients);

        // newClientUsers.forEach((cl) => {
        //   console.log(`Subscribing <>-<> ${cl.guid}@${config.xmppServer}`);
        //   subscribeToPresence(`${cl.guid}@${config.xmppServer}`);
        // });

        newConnection.forEach((client) => {
          client.users.forEach((user) => {
            // console.log(`Subscribing <>-<> ${user.guid}@${config.xmppServer}`);
            subscribeToPresence(`${user.guid}@${config.xmppServer}`);
          });
        });
      }, 3000); // 3000 milliseconds = 3 seconds

      // Clean up the interval when the component is unmounted

      return () => {
        // Disconnect when component unmounts
        // console.log("disconnecting...");
        clearInterval(presenceInterval);
        clearInterval(myPresenceInterval);
        xmppConnection.disconnect();
      };
    }
  }, [newConnection, newTeam, newClientUsers, receivedPresenceUpdate]);

  const userInChat = (jid) => {
    const searchJid = (
      jid.indexOf(config.xmppServer) == -1 ? `${jid}@${config.xmppServer}` : jid
    ).trim();
    const user = participants.find((user) => user.fromJid === searchJid);
    if (user) {
      return true;
    } else return false;
  };

  return (
    <div className="participants_popup_container open">
      <Tooltip id="my-tooltip" />
      <div className="fixed-top">
        <p className="popup-title">People</p>
        <span
          className="close-btn"
          onClick={closeDiv}
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Close"
        >
          ×
        </span>
      </div>
      <div className="chat-popup-container">
        <ParticipantsLabel title="Participants" />
        <Participants />
        {/* <div className="region-section"> */}
        <ParticipantsLabel title="Add Participant" />
        {hideClient === false && (
          <>
            <div className="region-title">
              {loginType === "pro" ? "Clients" : "Connections"}
            </div>
          </>
        )}

        <div className="client-cards-container">
          {loginType === "pro" && (
            <>
              {hideClient === false && (
                <>
                  {newConnection.map((client) => (
                    <>
                      {client.clientType.toLowerCase() === "consumer" ? (
                        <div className="client-card">
                          {/* <div className="client-name">{client.name}</div> */}
                          <div className="client-email">{client.adminName}</div>
                          <div className="action-buttons">
                            {!userInChat(
                              `${client.guid}@${config.xmppServer}`
                            ) && (
                              <button
                                className="action-button"
                                onClick={() =>
                                  makeCall({
                                    fullName: client.adminName,
                                    fromJid: decodedToken.toJid,
                                    toJid: client.guid,
                                  })
                                }
                                style={{
                                  display:
                                    client.online && client.users.length === 0
                                      ? "inline-block"
                                      : "none",
                                }}
                              >
                                Call&nbsp;&nbsp;<i className="fas fa-phone"></i>
                              </button>
                            )}
                            {userInChat(
                              `${client.guid}@${config.xmppServer}`
                            ) ? (
                              <>Active in Call</>
                            ) : client.online ? (
                              <></>
                            ) : (
                              <>offline</>
                            )}
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="group-card">
                            <div className="client-name">{client.name}</div>
                            <div className="group-button">
                              <div
                                className="expand-button"
                                onClick={() => toggleClientExpand(client.id)}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={
                                  client.isExpanded ? "Collapse" : "Expand"
                                }
                              >
                                {client.isExpanded ? "-" : "+"}
                              </div>
                            </div>
                          </div>
                          {client.isExpanded && (
                            <div className="sub-items">
                              {newClientUsers.map((member, index) => (
                                <div key={index} className="client-card">
                                  <div className="client-email">
                                    {member.name}
                                  </div>
                                  <div className="action-buttons">
                                    {!userInChat(
                                      `${member.guid}@${config.xmppServer}`
                                    ) && (
                                      <button
                                        className="action-button"
                                        onClick={() =>
                                          makeCall({
                                            fullName: member.name,
                                            fromJid: decodedToken.toJid,
                                            toJid: member.guid,
                                          })
                                        }
                                        style={{
                                          display: member.online
                                            ? "inline-block"
                                            : "none",
                                        }}
                                      >
                                        Call&nbsp;&nbsp;
                                        <i className="fas fa-phone"></i>
                                      </button>
                                    )}
                                    {member.online ? (
                                      userInChat(
                                        `${member.guid}@${config.xmppServer}`
                                      ) ? (
                                        <>Active in Call</>
                                      ) : (
                                        <></>
                                      )
                                    ) : (
                                      <>offline</>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ))}
                </>
              )}
              {/* Team Members here */}
              <div className="region-title">Team</div>
              <div className="client-cards-container">
                {newTeam.map((teamMember) => (
                  <>
                    <div className="group-card">
                      <div className="client-name">{teamMember.groupName}</div>
                      <div className="group-button">
                        <div
                          className="expand-button"
                          onClick={() => toggleExpand(teamMember.id)}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            teamMember.isExpanded ? "Collapse" : "Expand"
                          }
                        >
                          {teamMember.isExpanded ? "-" : "+"}
                        </div>
                      </div>
                    </div>
                    <>
                      {teamMember.isExpanded && (
                        <div className="sub-items">
                          {teamMember.users.map((member, index) => (
                            <div key={index} className="client-card">
                              <div className="client-email">{member.name}</div>
                              <div className="action-buttons">
                                {!userInChat(
                                  `${member.guid}@${config.xmppServer}`
                                ) && (
                                  <button
                                    className="action-button"
                                    onClick={() =>
                                      makeCall({
                                        fullName: member.name,
                                        fromJid: decodedToken.toJid,
                                        toJid: member.guid,
                                      })
                                    }
                                    style={{
                                      display: member.online
                                        ? "inline-block"
                                        : "none",
                                    }}
                                  >
                                    Call&nbsp;&nbsp;
                                    <i className="fas fa-phone"></i>
                                  </button>
                                )}
                                {member.online ? (
                                  userInChat(
                                    `${member.guid}@${config.xmppServer}`
                                  ) ? (
                                    <>Active in Call</>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <>offline</>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  </>
                ))}

                {/* <!-- Add more Team Members as needed --> */}
              </div>
              {/* --End Team Members here */}
            </>
          )}

          {loginType === "ind" && (
            <>
              {newConnection.map((client) => (
                <>
                  {client.biztype.toLowerCase() === "consumer" ? (
                    <div className="client-card">
                      {/* <div className="client-name">{client.name}</div> */}
                      <div className="client-email">{client.business_name}</div>
                      <div className="action-buttons">
                        {!userInChat(`${client.guid}@${config.xmppServer}`) && (
                          <button
                            className="action-button"
                            onClick={() =>
                              makeCall({
                                fullName: client.business_name,
                                fromJid: decodedToken.toJid,
                                toJid: client.guid,
                              })
                            }
                            style={{
                              display:
                                client.online && client.users.length === 0
                                  ? "inline-block"
                                  : "none",
                            }}
                          >
                            Call&nbsp;&nbsp;<i className="fas fa-phone"></i>
                          </button>
                        )}
                        {userInChat(`${client.guid}@${config.xmppServer}`) ? (
                          <>Active in Call</>
                        ) : client.online ? (
                          <></>
                        ) : (
                          <>offline</>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="group-card">
                        <div className="client-name">
                          {client.business_name}
                        </div>
                        <div className="group-button">
                          <div
                            className="expand-button"
                            onClick={() => toggleClientExpand(client.id)}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={
                              client.isExpanded ? "Collapse" : "Expand"
                            }
                          >
                            {client.isExpanded ? "-" : "+"}
                          </div>
                        </div>
                      </div>
                      {client.isExpanded && (
                        <div className="sub-items">
                          {newClientUsers.map((member, index) => (
                            <div key={index} className="client-card">
                              <div className="client-email">{member.name}</div>
                              <div className="action-buttons">
                                {!userInChat(
                                  `${member.guid}@${config.xmppServer}`
                                ) && (
                                  <button
                                    className="action-button"
                                    onClick={() =>
                                      makeCall({
                                        fullName: member.name,
                                        fromJid: decodedToken.toJid,
                                        toJid: member.guid,
                                      })
                                    }
                                    style={{
                                      display: member.online
                                        ? "inline-block"
                                        : "none",
                                    }}
                                  >
                                    Call&nbsp;&nbsp;
                                    <i className="fas fa-phone"></i>
                                  </button>
                                )}
                                {member.online ? (
                                  userInChat(
                                    `${member.guid}@${config.xmppServer}`
                                  ) ? (
                                    <>Active in Call</>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <>offline</>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </>
              ))}
            </>
          )}

          {/* <!-- Add more clients as needed --> */}
          {/* </div> */}
        </div>
      </div>
      {/* <!-- Add more region sections as needed --> */}
      {/* </div> */}
    </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setShowPopUsersAction: (showPopUsers) =>
      dispatch(setShowPopUsers(showPopUsers)),
    setIsRoomHostAction: (isRoomHost) => dispatch(setIsRoomHost(isRoomHost)),
    setToJidAction: (toJid) => dispatch(setToJid(toJid)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(PopupUsers);

import React, { useState } from "react";
import send_message_btn from "../../resources/images/sendMessageButton.svg";
import * as webRTCHandler from "../../utils/webRTCHandler";

const NewMessage = () => {
  const [message, setMessage] = useState("");

  const handleTextChange = (event) => {
    setMessage(event.target.value);
  };

  const handleKeyPressed = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      //send Message to other Users
      sendMessage();
    }
  };

  const sendMessage = () => {
    if (message.trim().length > 0) {
      webRTCHandler.sendMessageUsingDataChannel(message);
      setMessage("");
    }
  };

  return (
    <div className="new_message_container">
      <input
        className="new_message_input"
        value={message}
        onChange={handleTextChange}
        placeholder="Type your message..."
        onKeyDown={handleKeyPressed}
      />
      <img
        className="new_message_button"
        src={send_message_btn}
        alt="Send Message"
        onClick={sendMessage}
      />
    </div>
  );
};

export default NewMessage;
